<script setup lang="ts">
// import '@khmyznikov/pwa-install'
import * as localforage from 'localforage'
import MetaHead from '@/Components/Global/MetaHead.vue'
import AuthModal from '@/Components/Auth/AuthModal.vue'
import ToastContainer from '@/Components/UI/ToastContainer.vue'
import ConsentBanner from '@/Components/Global/ConsentBanner.vue'
import VerifyEmailBanner from '@/Components/Global/VerifyEmailBanner.vue'
import NavigationNavBar from '@/Components/Navigation/NavigationNavBar.vue'
import NavigationFooter from '@/Components/Navigation/NavigationFooter.vue'
import ArticleUpdatesModal from '@/Components/Article/ArticleUpdatesModal.vue'
import { router } from '@inertiajs/vue3'
import { useToast } from '@/Composables/useToast.ts'
import { useRoute } from '@/Composables/useRoute.ts'
import { computed, onMounted, ref, watch } from 'vue'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { useCurrentUrl } from '@/Composables/useCurrentUrl'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { NotificationType } from '@/Enums/NotificationType.ts'
import { useRestoreScroll } from '@/Composables/useRestoreScroll.js'
import { useAnalyticsEvent } from '@/Composables/useAnalyticsEvent.ts'
import type { BroadcastNotification } from '@/Types/BroadcastNotification'
import { useBroadcastNotifications } from '@/Composables/useBroadcastNotifications.ts'

defineProps({
    footer: {
        type: Boolean,
        default: true
    },
    navbar: {
        type: Boolean,
        default: true
    }
})

function runFlashMessage () {
    const flash = usePageProps().flash
    if (flash?.message) {
        setTimeout(() => {
            useToast(flash.message, flash.level || NotificationType.FAIL)
        }, 250)
    }
}

watch(() => usePageProps().flash, runFlashMessage)

useRestoreScroll()

onMounted(() => {
    localforage.setItem('authed', useIsAuthed())

    if (usePageProps().app?.pwaMode === true) {
        useAnalyticsEvent('Opened PWA')
    }

    setTimeout(() => {
        runFlashMessage()
    }, 0)

    if (useIsAuthed() && usePageProps().config.broadcasting.enabled) {
        useBroadcastNotifications((notification: BroadcastNotification) => {
            useToast(notification.title, notification.level, {
                description: notification.htmlContent,
                image: notification.image,
                url: notification.url
            })
        })
    }
})

const reduceMotion = computed(() => useIsAuthed() && usePageProps().auth.settings.reduce_motion)

const showVerifyBanner = ref(true)

router.on('navigate', () => {
    showVerifyBanner.value = useCurrentUrl() !== useRoute('verification.notice')
})
</script>

<template>
    <MetaHead />
    <AuthModal />
    <ArticleUpdatesModal />

    <teleport to="body">
        <ToastContainer />
    </teleport>
    <main
        :class="reduceMotion ? 'reduced-motion' : null"
        class="flex min-h-screen flex-col"
        scroll-region>
        <a
            class="skip-to-content-link"
            href="#main">
            Skip to content
        </a>
        <article class="flex flex-1 flex-col">
            <NavigationNavBar v-if="navbar" />
            <VerifyEmailBanner
                v-if="useIsAuthed() && !usePageProps().auth.email_verified && showVerifyBanner" />
            <div
                id="main"
                :class="footer ? '' : 'flex'"
                class="flex-1">
                <slot />
            </div>
            <NavigationFooter v-if="footer" />
        </article>
    </main>
    <ConsentBanner />
</template>

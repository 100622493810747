<template>
    <svg
        viewBox="0 0 250 250"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M52.4246 232.266C37.5181 232.266 24.9713 220.631 23.8966 205.771L11.747 41.215C11.2097 33.972 13.3826 26.8692 17.8218 21.1916C22.7751 14.8832 30.1816 10.9112 38.1489 10.3505L81.2097 7.1729C81.9807 7.12617 82.7517 7.07944 83.5228 7.07944C93.9667 7.07944 103.71 12.1495 109.457 20.4206C113.593 10.9813 122.612 4.11215 133.499 3.31775L178.499 0H179.738C194.761 0 207.378 11.729 208.476 26.729L220.626 191.285C221.77 207.009 209.831 220.748 193.967 221.939L149.971 225.187H148.756C138.873 225.187 129.387 220.304 123.359 212.103L123.009 211.636C121.864 214.299 120.322 216.799 118.406 219.019C113.383 224.86 106.35 228.388 98.6162 228.972L53.6162 232.29H52.448L52.4246 232.266Z"
            fill="var(--color-primary)" />
        <path
            d="M70.1348 249.977C55.2283 249.977 42.6815 238.341 41.6068 223.481L29.4572 58.9252C28.9199 51.6822 31.0928 44.5794 35.532 38.9018C40.4853 32.5934 47.8918 28.6214 55.8591 28.0607L98.9199 24.8831C99.6909 24.8364 100.462 24.7896 101.233 24.7896C111.677 24.7896 121.42 29.8597 127.168 38.1308C131.303 28.6915 140.322 21.8224 151.21 21.028L196.21 17.7102H197.448C212.471 17.7102 225.088 29.4392 226.186 44.4392L238.336 208.995C239.481 224.72 227.541 238.458 211.677 239.65L167.682 242.897H166.467C156.583 242.897 147.097 238.014 141.069 229.813L140.719 229.346C139.574 232.009 138.032 234.509 136.116 236.729C131.093 242.57 124.06 246.098 116.326 246.682L71.3264 250H70.1582L70.1348 249.977Z"
            fill="var(--color-primary)" />
        <path
            d="M179.411 25.0468L135.345 28.2945C133.593 28.4113 131.981 30.3038 132.144 32.2898L137.822 109.229L89.0367 34.9767C87.8919 33.0608 85.3218 32.0094 83.0788 32.173L39.9947 35.3505C38.0087 35.4907 36.6302 37.3599 36.7937 39.3459L48.9433 203.925C49.0835 205.678 50.6956 207.29 52.6816 207.15L96.747 203.902C98.99 203.738 100.345 201.893 100.228 200.14L94.5274 122.944L143.569 197.173C144.971 199.066 147.284 200.14 149.037 200.023L192.121 196.846C194.364 196.682 195.719 194.837 195.602 193.084L183.453 28.5047C183.312 26.5188 181.677 24.8833 179.434 25.0468H179.411Z"
            fill="white" />
    </svg>
</template>

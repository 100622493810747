import '../css/app.css'
import AOS from 'aos'
import { MotionPlugin } from '@vueuse/motion'
import 'aos/dist/aos.css'
import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
import VueSocialSharing from 'vue-social-sharing'
import {
    vTooltip
} from 'floating-vue'
import VueLazyLoad from 'vue3-lazyload'
import BaseLayout from '@/Layouts/BaseLayout.vue'

import { createApp, h } from 'vue'
import { createInertiaApp, router } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'

import.meta.glob(['../images/**/*'])

if (typeof window !== 'undefined') {
    if (import.meta.env.VITE_BROADCAST_CONNECTION !== 'log') {
        // @ts-expect-error
        window.Pusher = Pusher
        window.Echo = new Echo({
            broadcaster: import.meta.env.VITE_BROADCAST_CONNECTION,
            key: import.meta.env.VITE_REVERB_APP_KEY,
            wsHost: import.meta.env.VITE_REVERB_HOST,
            wsPort: import.meta.env.VITE_REVERB_PORT,
            wssPort: import.meta.env.VITE_REVERB_PORT,
            forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
            enabledTransports: ['ws', 'wss']
        })
    } else {
        window.Echo = null
    }

    AOS.init({
        once: true
    })
}

createInertiaApp({
    title: title => `${title} - ${import.meta.env.VITE_APP_NAME}`,
    // @ts-expect-error
    resolve: async (name) => {
        const pageImporter = resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue'))
        if (!pageImporter) {
            throw new Error(`Page not found: ${name}`)
        }

        const page = await pageImporter

        // @ts-expect-error
        page.default.layout = page.default.layout || ((h, p) => h(BaseLayout, () => [p]))

        return page
    },
    setup ({
        el,
        App,
        props,
        plugin
    }) {
        const app = createApp({
            render: () => h(App, props)
        })

        app.use(plugin)
        app.use(VueSocialSharing)
        app.use(MotionPlugin)
        app.use(VueLazyLoad, {})
        app.directive('tooltip', vTooltip)

        app.mount(el)
    },
    progress: {
        color: '#6E51FC',
        includeCSS: true,
        showSpinner: false
    }
}).then(r => '')

<script setup lang="ts">
import type { PropType } from 'vue'
import type { User } from '@/Types/Models/User'

const props = defineProps({
    user: [Object as PropType<User>, null],
    showVerified: {
        type: Boolean,
        default: false
    },
    roundedClass: {
        type: String,
        default: 'rounded-full'
    },
    classes: {
        type: String,
        default: ''
    },
    size: {
        type: String,
        default: 'medium'
    },
    type: {
        type: String,
        default: 'webp'
    },
    imageSize: {
        type: Number,
        default: 32
    }
})

function getImage () {
    const { avatars } = props.user
    const key = `${props.size}_${props.type}`

    if (!avatars[key]) {
        return avatars.original
    }

    const avatar = `${avatars[key]}`

    return avatar || avatars.original
}
</script>

<template>
    <div
        class="relative">
        <div
            class="aspect-square overflow-hidden bg-white"
            :class="[roundedClass, classes]">
            <img
                v-if="user"
                :alt="`Avatar for user: ${user.name}`"
                :height="imageSize"
                itemprop="image"
                :src="getImage()"
                :width="imageSize"
                class="h-full w-full object-cover object-center"
                loading="lazy">
            <img
                v-else
                :alt="`Empty Avatar`"
                :height="imageSize"
                itemprop="image"
                src="/images/app/avatar.svg"
                :width="imageSize"
                class="h-full w-full object-cover object-center"
                loading="lazy">
        </div>
    </div>
</template>

<script setup lang="ts">
import DropdownItem from '@/Components/UI/DropdownItem.vue'
import { cn } from '@/utils.ts'
import { autoUpdate, flip, useFloating } from '@floating-ui/vue'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { useMutationObserver, useActiveElement, useMagicKeys } from '@vueuse/core'
import { computed, onMounted, onUnmounted, ref, useId, useTemplateRef, watch } from 'vue'

const props = defineProps({
    items: {
        type: Array,
        default: () => []
    },
    shortcut: {
        type: [String, null]
    },
    menuWidth: {
        type: String,
        default: 'w-56'
    },
    maxHeight: {
        type: String,
        default: 'max-h-[calc(100dvh-4rem)]'
    },
    toggleClasses: {
        type: String,
        default: ''
    },
    listClasses: {
        type: String,
        default: ''
    },
    isOpen: {
        type: [Boolean, null],
        default: false
    }
})

const id = useId()
const emit = defineEmits(['opened', 'closed'])
const menuToggle = useTemplateRef('toggle-' + id)
const itemsList = ref(null)

const keys = useMagicKeys({
    passive: true
})
const pressedShortcut = keys[props.shortcut]
const activeElement = useActiveElement()
const notUsingInput = computed(() =>
    activeElement.value?.tagName !== 'INPUT' &&
    activeElement.value?.tagName !== 'TEXTAREA'
)

const notEmpty = (value) => {
    return value !== undefined && value !== null && value !== ''
}

function shouldShow (item) {
    let canShow = (notEmpty(item.if) ? item.if : true)

    if (typeof item.permission !== 'undefined') {
        canShow = item.permission === true
    }

    return canShow
}

function open () {
    menuToggle.value.el.click()
}

const { floatingStyles } = useFloating(menuToggle, itemsList, {
    placement: 'bottom-end',
    whileElementsMounted: autoUpdate,
    middleware: [flip()]
})

const updateState = () => {
    const isExpanded = menuToggle.value.el.getAttribute('aria-expanded') === 'true'
    isExpanded ? emit('opened') : emit('closed')
}

watch(pressedShortcut, (value) => {
    if (value && notUsingInput.value) {
        menuToggle.value.el.click()
    }
})

onMounted(() => {
    if (props.isOpen) {
        open()
    }

    useMutationObserver(menuToggle, (mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.type === 'attributes' && mutation.attributeName === 'aria-expanded') {
                updateState()
            }
        })
    }, {
        attributes: true
    })
})
</script>

<template>
    <Menu
        as="div">
        <MenuButton
            :id="'toggle-' + id"
            :ref="'toggle-' + id"
            :class="toggleClasses"
            class="flex items-center rounded-full outline-primary">
            <slot name="toggle" />
        </MenuButton>
        <Teleport
            to="body">
            <div
                ref="itemsList"
                class="absolute z-50 mt-2"
                :style="floatingStyles">
                <Transition
                    enter-active-class="transition duration-100 ease-out"
                    enter-from-class="scale-95 transform opacity-0"
                    enter-to-class="scale-100 transform opacity-100"
                    leave-active-class="transition duration-75 ease-in"
                    leave-from-class="scale-100 transform opacity-100"
                    leave-to-class="scale-95 transform opacity-0"
                >
                    <MenuItems
                        :class="cn('origin-top-right overflow-auto border bg-white border-transparent outline-primary dropdown-container dark:border-white/10 dark:bg-zinc-800', menuWidth, maxHeight, listClasses)"
                        @change="updateState">
                        <slot name="items" />
                        <div
                            v-for="(item, index) in items"
                            :key="index">
                            <div
                                v-if="item.url === null && item.title === 'sep' && shouldShow(item)"
                                class="my-2 w-full">
                                <div class="h-px w-full bg-primary/5 dark:bg-zinc-700" />
                            </div>
                            <MenuItem
                                v-else-if="shouldShow(item)"
                                v-slot="{ active }"
                                :item="item">
                                <DropdownItem
                                    :active="active"
                                    :item="item" />
                            </MenuItem>
                        </div>
                    </MenuItems>
                </Transition>
            </div>
        </Teleport>
    </Menu>
</template>

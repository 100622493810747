<script setup>
import Button from '@/Components/UI/Button.vue'
import patternImage from '~/app/patterns/logo.svg'
import useEmitter from '@/Composables/useEmitter.js'
import Checkmark from '@/Components/Icon/Checkmark.vue'
import NotificationIconLevelUp from '@/Components/Notification/NotificationIconLevelUp.vue'
import NotificationIconRejected from '@/Components/Notification/NotificationIconRejected.vue'
import NotificationIconBadgeEarned from '@/Components/Notification/NotificationIconBadgeEarned.vue'
import { Link } from '@inertiajs/vue3'
import { useTimeAgo } from '@vueuse/core'
import { onMounted, ref, watch } from 'vue'
import { usePost } from '@/Composables/usePost.js'
import { XMarkIcon } from '@heroicons/vue/24/solid'
import { useRoute } from '@/Composables/useRoute.ts'
import { useToast } from '@/Composables/useToast.ts'
import { useIsBreakpoint } from '@/Composables/useIsBreakpoint.ts'

const props = defineProps({
    notification: Object,
    actions: {
        type: Boolean,
        default: true
    },
    small: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['read', 'unread', 'clicked'])
const isMobile = useIsBreakpoint('md')
const timeAgo = useTimeAgo(props.notification.created_at)
const isUnread = ref(!props.notification.read_at)
const isSmall = ref(props.small)

const iconMap = {
    'App\\Notifications\\ArticleRejectedNotification': NotificationIconRejected,
    'App\\Notifications\\ArticleUnpublishedNotification': NotificationIconRejected,
    'App\\Notifications\\LevelUpNotification': NotificationIconLevelUp,
    'App\\Notifications\\NewBadgeEarnedNotification': NotificationIconBadgeEarned
}

function markAsRead (e) {
    usePost({
        url: useRoute('api.notifications.read.single', { notificationId: props.notification.id }),
        onUnauthorized: () => useToast('Unauthorized action', 'fail'),
        onSuccess: () => {
            isUnread.value = false
            emit('read')
            useEmitter.emit('notification.read')
        }
    })
}

function markAsUnread (e) {
    usePost({
        url: useRoute('api.notifications.unread.single', { notificationId: props.notification.id }),
        onUnauthorized: () => useToast('Unauthorized action', 'fail'),
        onSuccess: (response) => {
            isUnread.value = true
            emit('unread')
            useEmitter.emit('notification.unread')
        }
    })
}

watch(isMobile, () => {
    if (!props.small) {
        isSmall.value = isMobile.value
    }
})

onMounted(() => {
    if (!props.small) {
        isSmall.value = isMobile.value
    }
})
</script>

<template>
    <div
        :class="[
            isUnread ? 'bg-white dark:bg-zinc-800/50' : 'bg-background/50 dark:bg-zinc-900',
            isSmall ? 'p-2.5' : 'p-4'
        ]"
        :href="props.notification.data.url ?? '#'"
        class="relative mb-1 flex group w-full items-center rounded-lg transition-colors">
        <div class="relative flex w-full items-center gap-4">
            <div class="relative flex w-full items-center gap-4 md:w-10/12">
                <Link
                    :href="notification.data?.url ? notification.data.url : null"
                    class="relative flex aspect-square w-16 items-center justify-center overflow-hidden rounded-md min-w-16 bg-zinc-950 dark:bg-primary">
                    <img
                        v-if="notification.data.image"
                        v-lazy="{src: notification.data.image, error: patternImage}"
                        :alt="`Thumbnail image for single notification: ${notification.id}`"
                        class="h-full object-cover">
                    <component
                        :is="iconMap[notification.type]"
                        v-else />
                </Link>
                <div
                    class="flex flex-col text-sm"
                    :class="{'sm:text-base' : !isSmall, 'leading-[1.25]': isSmall}">
                    <Link
                        :href="notification.data?.url ? notification.data.url : null"
                        :class="{'line-clamp-2': isSmall}"
                        class="hover:text-primary dark:hover:text-primary-light transition-colors"
                        @click="emit('clicked')"
                        v-html="notification.data.htmlContent" />
                    <div class="mt-1 justify-end text-xs text-zinc-400">
                        {{ timeAgo }}
                    </div>
                </div>
            </div>
            <div
                v-if="actions"
                :class="isSmall ? 'pr-2' : ''"
                class="flex justify-end w-2/12">
                <Button
                    v-if="isUnread"
                    v-tooltip="isSmall ? `Mark as read` : null"
                    variant="primary"
                    class="w-full md:w-auto"
                    :small="isSmall"
                    :icon="isSmall ? Checkmark : null"
                    :icon-only="isSmall"
                    @click="markAsRead">
                    {{ isSmall ? null : 'Mark as read' }}
                </Button>
                <Button
                    v-if="!isUnread"
                    variant="white"
                    class="w-full md:w-auto"
                    :small="isSmall"
                    :icon="isSmall ? XMarkIcon : null"
                    :icon-only="isSmall"
                    @click="markAsUnread">
                    {{ isSmall ? null : 'Mark as unread' }}
                </Button>
            </div>
        </div>
    </div>
</template>

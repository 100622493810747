<script setup lang="ts">
import { Link, router } from '@inertiajs/vue3'
import type { NavItem } from '@/Types/NavItem'
import { onMounted, ref, type PropType } from 'vue'
import type { Category } from '@/Types/Models/Category'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { useContrast } from '@/Composables/useContrast.ts'
import { useCurrentUrl } from '@/Composables/useCurrentUrl.js'

const props = defineProps({
    item: Object as PropType<NavItem>
})

const dropdownClicked = ref(false)
const active = ref(false)

async function hideDropdown () {
    dropdownClicked.value = true
    setTimeout(() => {
        dropdownClicked.value = false
    }, 300)
}

function markActive () {
    const currentUrl = useCurrentUrl()
    active.value =
        currentUrl === props.item.url ||
        currentUrl + '/' === props.item.url ||
        currentUrl === props.item.url + '/'

    if (props.item.children?.length) {
        props.item.children.forEach((child: NavItem | Category) => {
            if (currentUrl === child.url) {
                active.value = true
            }
        })
    }
}

function canShowItem (item: NavItem): boolean {
    let canShow = item.if !== undefined ? item.if : true

    if (item.permission !== undefined && canShow) {
        canShow = item.permission === true
    }

    return canShow
}

onMounted(() => {
    router.on('navigate', () => {
        markActive()
    })
    markActive()
})
</script>

<template>
    <component
        :is="item.url ? Link : 'button'"
        v-if="canShowItem(item)"
        :class="[
            dropdownClicked ? '' : 'group',
            active ? 'text-primary dark:text-white' : 'text-zinc-900 dark:text-white'
        ]"
        :href="item.url"
        class="relative flex h-full select-none items-center border border-transparent px-2 py-2 text-sm lg:px-3">
        <div
            :class="active ? 'opacity-100' : 'opacity-0'"
            class="absolute top-1/2 left-1/2 -z-10 h-9 w-full -translate-x-1/2 -translate-y-1/2 rounded-lg transition-all bg-primary/5 group-hover:opacity-100 dark:bg-white/10"
        />
        <!--        <div-->
        <!--            :class="active ? 'opacity-100' : 'opacity-0'"-->
        <!--            class="absolute bottom-0 left-0 h-1 w-full translate-y-px bg-primary"-->
        <!--        />-->

        <component
            :is="active && item.activeIcon ? item.activeIcon : item.icon"
            v-if="item.icon && ['object', 'function'].includes(typeof item.icon)"
            class="mr-2 w-5"
        />
        {{ item.title }}

        <ChevronDownIcon
            v-if="item.children"
            class="ml-1 w-4"
        />

        <div
            v-if="item.children"
            class="pointer-events-none absolute top-full mt-px left-0 -ml-px w-96 -translate-y-4 text-zinc-900 opacity-0 transition-all group-hover:pointer-events-auto group-hover:-translate-y-1 group-hover:opacity-100 group-focus:-translate-y-1 group-focus:opacity-100 dark:border-zinc-700">
            <ul class="rounded-lg max-h-[calc(100dvh-4rem)] overflow-auto  border border-zinc-200 bg-white p-1 shadow-sm dark:border-zinc-700 dark:bg-zinc-800">
                <template
                    v-for="(child, index) in item.children"
                    :key="index">
                    <li
                        v-if="canShowItem(child)"
                        class="flex w-full"
                    >
                        <Link
                            :href="child.url"
                            :class="useCurrentUrl() === child.url
                                ? 'bg-primary/10 dark:bg-white/10'
                                : 'hover:bg-primary/5 dark:hover:bg-white/5'"
                            class="flex w-full group/navitem select-none items-center gap-3 rounded-md py-2.5 px-2 dark:text-white"
                            @click="hideDropdown"
                        >
                            <div
                                class="size-12 transition-all group-hover/navitem:scale-105 duration-300 shrink-0 bg-primary flex rounded-lg border items-center justify-center"
                                :class="[
                                    child?.colour ? '' : 'bg-linear-to-tr bg-gradient-app',
                                    useContrast(child.colour, 'text-zinc-700', 'text-white'),
                                    useContrast(child.colour, 'border-zinc-700', 'border-0'),
                                    index % 2 ? 'group-hover/navitem:rotate-5' : 'group-hover/navitem:-rotate-5',
                                ]"
                                :style="{backgroundColor: child?.colour}">
                                <component
                                    :is="child.icon"
                                    v-if="child.icon && typeof child.icon !== 'string'"
                                    class="size-6 transition-all"
                                />
                                <img
                                    v-if="child.icon && typeof child.icon === 'string'"
                                    :alt="child.title"
                                    class="size-6 transition-all"
                                    :src="child.icon"
                                >
                            </div>
                            <div class="flex flex-col items-start justify-start text-left">
                                <p class="text-sm font-semibold ">
                                    {{ child.title }}
                                </p>
                                <p
                                    v-if="child.description"
                                    class="text-xs text-pretty -mt-0.5 text-zinc-500 dark:text-zinc-400 line-clamp-1">
                                    {{ child.description }}
                                </p>
                            </div>
                        </Link>
                    </li>
                </template>
            </ul>
        </div>
    </component>
</template>

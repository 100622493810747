<script setup lang="ts">
import { useAttrs } from 'vue'
import { cn } from '@/utils.ts'

const attrs = useAttrs()
</script>

<template>
    <div :class="cn('flex items-center gap-4 rounded-xl text-zinc-900 bg-primary0 dark:bg-zinc-800 p-2.5', attrs.class)">
        <div class="flex w-full flex-col">
            <!-- Thumbnail Section -->
            <div class="flex w-full items-center gap-4">
                <div class="flex aspect-square w-16 shrink-0 overflow-hidden rounded-lg">
                    <div
                        class="relative h-full w-full bg-primary/10 dark:bg-zinc-700"
                        data-placeholder="icon" />
                </div>

                <div class="flex w-full items-center">
                    <div class="flex w-full flex-col lg:w-auto lg:grow">
                        <div class="flex h-full flex-col gap-2">
                            <div
                                class="h-2.5 w-9/12 rounded-sm -mt-0.5 bg-primary/10 dark:bg-zinc-700"
                                data-placeholder="name" />
                            <div
                                class="h-2.5 w-32 rounded-sm -mt-0.5 bg-primary/10 dark:bg-zinc-700"
                                data-placeholder="name" />
                            <div
                                class="h-1.5 w-16 mt-1 rounded-sm -mt-0.5 bg-primary/10 dark:bg-zinc-700"
                                data-placeholder="name" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Bluesky from '@/Components/Icon/Bluesky.vue'
import Facebook from '@/Components/Icon/Facebook.vue'
import TwitterX from '@/Components/Icon/TwitterX.vue'
import Instagram from '@/Components/Icon/Instagram.vue'
import { usePageProps } from '@/Composables/usePageProps.ts'

type Item = {
    name: string
    url: string
    icon: any
    colours: string
}

const props = defineProps({
    labels: {
        type: Boolean,
        default: false
    },
    white: {
        type: Boolean,
        default: false
    }
})

const items: Item[] = [
    {
        name: 'Twitter/X',
        url: usePageProps().config.social_media.twitter.url,
        icon: TwitterX,
        colours: props.white ? 'text-white' : 'text-black dark:text-white'
    },
    {
        name: 'Facebook',
        url: usePageProps().config.social_media.facebook.url,
        icon: Facebook,
        colours: props.white ? 'text-white' : 'text-[#1778F2] dark:bg-white rounded-full'
    },
    {
        name: 'Instagram',
        url: usePageProps().config.social_media.instagram.url,
        icon: Instagram,
        colours: props.white ? 'text-white' : 'text-[#bc2a8d]'
    },
    // {
    //     name: 'Discord',
    //     url: usePageProps().config.social_media.discord.url,
    //     icon: Icon.Discord,
    //     colours: 'bg-[#5864f2]'
    // },
    // {
    //     name: 'Threads',
    //     url: usePageProps().config.social_media.threads.url,
    //     icon: Threads,
    //     colours: props.white ? 'text-white' : 'text-black dark:text-white'
    // },
    {
        name: 'BlueSky',
        url: usePageProps().config.social_media.bluesky.url,
        icon: Bluesky,
        colours: props.white ? 'text-white' : 'text-[#1083fe] dark:text-white'
    }
]
</script>

<template>
    <ul class="flex w-full justify-between max-w-[200px]">
        <li
            v-for="item in items"
            :key="item.url"
            class="relative flex flex-col items-center justify-center">
            <a
                :class="item.colours"
                :href="item.url"
                target="_blank"
                class="flex w-full translate-y-0 items-center overflow-hidden transition-all group hover:-translate-y-1">
                <div
                    class="pointer-events-none absolute top-0 left-0 h-full w-full bg-white opacity-0 transition" />
                <div
                    class="relative flex h-full w-full items-center justify-center"
                    itemprop="sameAs">
                    <span class="sr-only">Link to {{ item.name }} page</span>
                    <component
                        :is="item.icon"
                        class="h-7 transition-all group-hover:opacity-60" />
                </div>
            </a>
            <div
                v-if="labels"
                class="absolute top-full left-1/2 mt-1 -translate-x-1/2 text-xs">
                {{ item.name }}
            </div>
        </li>
    </ul>
</template>

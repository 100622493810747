<script setup>
import useEmitter from '@/Composables/useEmitter.js'
import { onMounted, ref } from 'vue'
import { useTitle } from '@vueuse/core'
import { Head, router } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useMakeRequest } from '@/Composables/useMakeRequest.js'

const meta = ref(usePageProps().meta)
const key = ref(new Date().toDateString())
const currentTitle = ref('')

function formatTitle (title, notificationsCount = null) {
    if (title === null) {
        title = 'untitled'
    }
    if (useIsAuthed()) {
        if (!notificationsCount) {
            notificationsCount = usePageProps().auth.notifications
        }
        if (notificationsCount > 0) {
            return `(${notificationsCount}) ${title}`
        }
    }
    return title
}

function applyMeta (event = null) {
    meta.value = usePageProps().meta
    key.value = event ? (event.timeStamp + event.detail.page.component) : new Date().toDateString()
    currentTitle.value = meta.value?.title

    useTitle(formatTitle(meta.value?.title))

    document.querySelector('meta[name="description"]').setAttribute('content', meta.value?.description ?? '')
}

router.on('success', (event) => applyMeta(event))

function reloadNotificationsCount () {
    useMakeRequest(useRoute('api.notifications.count'), 'GET').then(response => {
        const unreadCount = response.unreadCount
        useTitle(formatTitle(currentTitle.value, unreadCount))
    })
}

useEmitter.on('notification.read', reloadNotificationsCount)
useEmitter.on('notification.unread', reloadNotificationsCount)
useEmitter.on('notifications.clear', reloadNotificationsCount)

onMounted(() => applyMeta())
</script>

<template>
    <div :key="key">
        <Head
            :description="meta?.description"
            :title="formatTitle(meta?.title)" />
    </div>
</template>

<script setup lang="ts">

import Card from '@/Components/UI/Card.vue'
import Button from '@/Components/UI/Button.vue'
import CookieBite from '@/Components/Icon/CookieBite.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import { onMounted, ref } from 'vue'
import { useCookies } from '@vueuse/integrations/useCookies'

const show = ref(false)
let cookies = null

function setCookie () {
    cookies.set('cookie_consent', true, {
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 5))
    })
    show.value = !show.value
}

onMounted(() => {
    cookies = useCookies()

    if (!cookies.get('cookie_consent')) {
        show.value = true
    }
})
</script>

<template>
    <transition
        enter-active-class="duration-500 ease-out"
        enter-from-class="translate-y-8 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-8 opacity-0">
        <div
            v-if="show"
            class="fixed right-0 bottom-0 z-20 w-96 max-w-full p-4">
            <Card
                border
                class="flex w-full gap-1 flex-col">
                <div class="flex items-center gap-4">
                    <p class="text-base font-semibold">
                        Cookies
                    </p>
                </div>
                <div class="flex flex-col gap-4">
                    <p class="text-sm/5 text-zinc-600">
                        This website uses cookies to ensure you get the best experience on our website. <a
                            href="https://www.cookiesandyou.com/"
                            target="_blank"
                            rel="nofollow"
                            class="font-semibold underline">Learn more</a>.
                    </p>
                    <div class="flex w-1/3 ml-auto">
                        <Button
                            class="w-full"
                            variant="primary"
                            small
                            @click="setCookie">
                            Okay
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    </transition>
</template>

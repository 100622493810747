<script setup lang="ts">
import Button from '@/Components/UI/Button.vue'
import Dropdown from '@/Components/UI/Dropdown.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import NotificationBell from '@/Components/Navigation/NotificationBell.vue'
import NotificationSkeleton from '@/Components/UI/NotificationSkeleton.vue'
import NotificationSingle from '@/Components/Notification/NotificationSingle.vue'
import { onMounted, ref } from 'vue'
import { useTitle } from '@vueuse/core'
import { BellIcon } from '@heroicons/vue/24/solid'
import { useRoute } from '@/Composables/useRoute.ts'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useMakeRequest } from '@/Composables/useMakeRequest.js'
import { useIsBreakpoint } from '@/Composables/useIsBreakpoint.ts'
import { useBroadcastNotifications } from '@/Composables/useBroadcastNotifications.ts'

type NotificationFilter = 'all' | 'read' | 'unread'
const notificationFilters = [
    {
        label: 'Unread',
        value: 'unread'
    },
    {
        label: 'Read',
        value: 'read'
    },
    {
        label: 'All',
        value: 'all'
    }
]

const isOpen = ref(false)
const isMobile = useIsBreakpoint('md')
const count = ref(usePageProps().auth.notifications)
const notifications = ref([])
const selectedType = ref('unread')
const loading = ref(false)

function getNotificationsCount (type: NotificationFilter = 'unread') {
    useMakeRequest(useRoute('api.notifications.count'), 'GET').then(response => {
        const totalCount = response.totalCount
        const readCount = response.readCount
        const unreadCount = response.unreadCount

        if (type === 'all') {
            count.value = totalCount
        } else if (type === 'read') {
            count.value = readCount
        } else if (type === 'unread') {
            count.value = unreadCount
        }

        setTimeout(() => {
            const originalTitle = usePageProps()?.meta?.title
            if (unreadCount > 0) {
                useTitle(`(${unreadCount}) ${originalTitle}`)
                if ('setAppBadge' in navigator) {
                    navigator.setAppBadge(unreadCount)
                }
            } else {
                useTitle(originalTitle)
                if ('setAppBadge' in navigator) {
                    navigator.clearAppBadge()
                }
            }
        }, 500)
    })
}

function getNotifications (type: NotificationFilter = 'unread') {
    loading.value = true
    useMakeRequest(useRoute('api.notifications.index', { type }), 'GET').then(response => {
        notifications.value = response
        loading.value = false
    })
}

function dropdownOpened () {
    isOpen.value = true
    getNotifications(selectedType.value)
}

function dropdownClosed () {
    isOpen.value = false
}

// When pusher receives a new notification
useBroadcastNotifications(() => getNotificationsCount())

// When pusher receives a notifications update
if (typeof window !== 'undefined' && useIsAuthed() && window.Echo !== null) {
    window.Echo.private(`App.Models.User.${usePageProps().auth.user.id}`)
        .listen('Users.NotificationsUpdatedEvent', getNotificationsCount)
}

function notificationClicked () {
    alert('clicked!')
}

function switchType (type: NotificationFilter) {
    selectedType.value = type
    getNotifications(type)
    getNotificationsCount(type)
}

onMounted(() => {
    getNotificationsCount()

    // 10 minutes
    setInterval(getNotificationsCount, 600000)
})
</script>

<template>
    <div class="relative flex h-full items-center">
        <NotificationBell
            v-if="isMobile"
            linked
            :count="count" />
        <Dropdown
            v-else
            shortcut="q"
            menu-width="w-110"
            toggle-classes="outline-none focus:outline-none"
            list-classes="h-128 outline-none focus:outline-none bg-background"
            @opened="dropdownOpened"
            @closed="dropdownClosed">
            <template #toggle>
                <NotificationBell
                    :open="isOpen"
                    :count="count" />
            </template>

            <template #items>
                <div class="flex flex-col gap-2 pt-2 pb-4 overflow-auto -my-2">
                    <div class="p-2 pt-1.5 flex items-center justify-between">
                        <SectionTitle font-size="text-xl">
                            {{ count }} Notifications
                        </SectionTitle>
                        <div class="flex gap-2">
                            <button
                                v-for="filter in notificationFilters"
                                :key="filter.value"
                                class="rounded-full dark:text-white bg-primary/0 transition-colors text-xs px-4 py-1"
                                :class="{
                                    'text-primary bg-primary/10 dark:bg-white/10': selectedType === filter.value,
                                    'text-zinc-800 hover:bg-primary/5 dark:hover:bg-white/5': selectedType !== filter.value
                                }"
                                @click="switchType(filter.value)">
                                {{ filter.label }}
                            </button>
                        </div>
                    </div>
                    <div
                        v-if="loading"
                        class="flex flex-col gap-1">
                        <NotificationSkeleton
                            v-for="n in 4"
                            :key="n" />
                    </div>
                    <ul
                        v-if="!loading && notifications && notifications.length > 0"
                        class="flex flex-col gap-1">
                        <li
                            v-for="notification in notifications"
                            :key="notification.id">
                            <NotificationSingle
                                small
                                :notification="notification"
                                @clicked="notificationClicked" />
                        </li>
                    </ul>
                    <div class="mt-auto pt-4 w-full flex">
                        <Button
                            class="w-full"
                            variant="primary-light"
                            :icon="BellIcon"
                            icon-first
                            :href="useRoute('dashboard.user.notifications')">
                            See all notifications
                        </Button>
                    </div>
                </div>
            </template>
        </Dropdown>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { usePageProps } from '@/Composables/usePageProps.ts'

defineProps({
    colour: String,
    adSlot: String | Number,
    showLink: {
        type: Boolean,
        default: true
    },
    padding: {
        type: String,
        default: 'px-2 sm:px-6 lg:px-8'
    },
    adFormat: {
        type: String,
        default: 'auto'
    },
    adLayout: {
        type: [String, null],
        default: null
    },
    adLayoutKey: {
        type: [String, null],
        default: null
    }
})

const show = computed(() => usePageProps().ads.enabled)
</script>

<template>
    <div
        v-if="show"
        class="container mx-auto w-full px-0">
        <!--        <div-->
        <!--            :class="[padding]"-->
        <!--            class="mx-auto w-full max-w-6xl rounded-xl bg-zinc-700/10 text-center min-h-16 dark:bg-white/10">-->
        <!--            <ins-->
        <!--                class="adsbygoogle"-->
        <!--                :data-ad-format="adFormat"-->
        <!--                :data-ad-layout-key="adLayoutKey"-->
        <!--                :data-ad-layout="adLayout"-->
        <!--                data-full-width-responsive="true"-->
        <!--                :data-ad-client="usePageProps().ads.scriptId"-->
        <!--                :data-ad-slot="usePageProps().ads.blocks[adSlot]"-->
        <!--                style="display: block" />-->
        <!--            <component :is="'script'">-->
        <!--                (adsbygoogle = window.adsbygoogle || []).push({})-->
        <!--            </component>-->
        <!--        </div>-->
    </div>
</template>

<script setup lang="ts">
import patternImage from '~/app/patterns/logo.svg'
import CategoryLink from '@/Components/Category/CategoryLink.vue'
import ArticleBookmarkButton from '@/Components/Article/ArticleBookmarkButton.vue'
import { Link } from '@inertiajs/vue3'
import { computed, type PropType, ref } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Article } from '@/Types/Models/Article'
import { ArchiveBoxIcon } from '@heroicons/vue/24/outline/index.js'

const props = defineProps({
    article: Object as PropType<Article>,
    size: {
        type: String,
        default: 'small'
    },
    prefetch: {
        type: Boolean,
        default: true
    },
    actions: {
        type: Boolean,
        default: true
    },
    smallActions: {
        type: Boolean,
        default: false
    },
    useSrcSet: {
        type: Boolean,
        default: false
    },
    loading: {
        type: String as PropType<'lazy' | 'eager'>,
        default: 'lazy'
    },
    showCategory: {
        type: Boolean,
        default: false
    },
    aspect: {
        type: String,
        default: 'aspect-article-thumb'
    },
    rounded: {
        type: String,
        default: 'rounded-xl'
    },
    urlOverride: [String, null]
})

const emit = defineEmits(['click'])

const isBookmarked = ref(props.article.bookmarked)

const url = computed(() => {
    return props.urlOverride || props.article.permalink
})
</script>

<template>
    <div
        :class="[rounded, aspect, ['large', 'full'].includes(size) ? 'bg-[size:100px] lg:aspect-16/9' : 'bg-[size:50px]']"
        :style="`background-image:url('${patternImage}')`"
        class="relative w-full overflow-hidden bg-center bg-repeat"
        @click="actions ? null : emit('click')">
        <component
            :is="url ? Link : 'div'"
            v-if="actions"
            :prefetch="null"
            :href="url"
            class="absolute bottom-0 left-0 z-10 h-full w-full opacity-0 peer">
            (link to article)
        </component>

        <div
            v-if="article.featured_image"
            class="h-full w-full bg-transparent peer-focus:bg-white ease-nice group-hover:bg-white group-focus:bg-white md:transition-all">
            <img
                itemprop="image"
                :loading="loading"
                :alt="`Thumbnail image for ${article.title}`"
                :src="article.featured_image[size + '_webp']"

                :srcset="useSrcSet ? `${article.featured_image?.small_webp} 425w,
                        ${article.featured_image?.medium_webp} 768w,
                        ${article.featured_image?.large_webp} 1024w,
                        ${article.featured_image?.full_webp} 1800w` : null"

                :sizes="useSrcSet ? `(max-width: 425px) 425px,(max-width: 768px) 768px,(max-width: 1024px) 1024px,1800px` : null"

                class="my-0 h-full w-full object-cover object-center peer-focus:opacity-90 transition-all ease-nice group-hover:opacity-90 group-focus:opacity-90">
        </div>

        <div
            v-if="showCategory"
            class="absolute z-20 flex items-center gap-2 left-1.5 top-1.5">
            <CategoryLink
                v-if="article.category"
                :category="article.category"
                :size="size" />

            <Link
                v-if="article.isArchive"
                :href="useRoute('articles.archived')"
                class="flex select-none items-center rounded-sm bg-black py-1 text-xs text-white transition-colors px-2.5 space-x-1">
                <ArchiveBoxIcon class="w-4" />
                <div>Archived</div>
            </Link>
        </div>

        <div
            v-if="actions"
            :class="[isBookmarked ? 'md:opacity-100' : 'md:opacity-30', smallActions ? 'scale-75 left-1 top-1' : 'right-2.5 top-2.5']"
            class="absolute z-10 opacity-100 transition-all group-hover:opacity-100 group-focus:opacity-100">
            <ArticleBookmarkButton
                :article="article"
                small
                :bookmarked="isBookmarked"
                @bookmarked="isBookmarked = true"
                @unbookmarked="isBookmarked = false" />
        </div>
    </div>
</template>

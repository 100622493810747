<script setup lang="ts">
import Modal from '@/Components/UI/Modal.vue'
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter'
import { usePost } from '@/Composables/usePost'
import type { Game } from '@/Types/Models/Game'
import { type PropType, ref, useAttrs } from 'vue'
import { useToast } from '@/Composables/useToast.ts'
import { UserPermission } from '@/Enums/UserPermission'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { usePermissions } from '@/Composables/usePermissions'
import { PlusIcon, MinusIcon } from '@heroicons/vue/20/solid'
import { NotificationType } from '@/Enums/NotificationType.ts'
import { useAnalyticsEvent } from '@/Composables/useAnalyticsEvent.ts'

const props = defineProps({
    followUrl: String,
    unfollowUrl: String,
    game: Object as PropType<Game>,
    variant: {
        type: String,
        default: 'primary'
    },
    confirmUnfollow: {
        type: Boolean,
        default: true
    },
    showLoginModal: {
        type: Boolean,
        default: true
    },
    padding: {
        type: String
    },
    small: {
        type: Boolean,
        default: false
    },
    xSmall: {
        type: Boolean,
        default: false
    },
    iconOnly: {
        type: Boolean,
        default: false
    },
    following: Boolean
})

const emit = defineEmits(['followed', 'unfollowed', 'updated'])

const isFollowing = ref(props.game.isFollowing)
const displayConfirmUnfollow = ref(false)

useEmitter.on('followersUpdated', function (e: { game: number, data: any }) {
    if (e.game === props.game.identifier) {
        isFollowing.value = e.data.followed
    }
})

function follow () {
    isFollowing.value = true
    usePost({
        url: props.game.followUrl,
        data: {
            user_id: usePageProps().auth.user.id
        },
        onUnauthorized: () => useToast('Unauthorized action', NotificationType.FAIL),
        onSuccess: response => {
            useToast(response.message, NotificationType.SUCCESS)
            emit('followed')
            emit('updated', {
                game: props.game.identifier,
                data: response
            })
            useEmitter.emit('followed', {
                game: props.game.identifier,
                data: response
            })
            useEmitter.emit('followersUpdated', {
                game: props.game.identifier,
                data: response
            })

            useAnalyticsEvent('Follow game')
        }
    })
}

function unfollow () {
    isFollowing.value = false
    displayConfirmUnfollow.value = false
    usePost({
        url: props.game.unfollowUrl,
        method: 'DELETE',
        data: {
            user_id: usePageProps().auth.user.id
        },
        onUnauthorized: () => useToast('Unauthorized action', NotificationType.FAIL),
        onSuccess: response => {
            useToast(response.message, NotificationType.SUCCESS)
            emit('unfollowed')
            emit('updated', {
                game: props.game.identifier,
                data: response
            })
            useEmitter.emit('followed', {
                game: props.game.identifier,
                data: response
            })
            useEmitter.emit('followersUpdated', {
                game: props.game.identifier,
                data: response
            })
            useAnalyticsEvent('Unfollow game')
        }
    })
}

function handleClick () {
    if ((!useIsAuthed() || !usePermissions(UserPermission.FOLLOW_GAME)) && props.showLoginModal) {
        useEmitter.emit('openAuthModal', {
            loginTitle: 'Login to follow ' + props.game.name,
            registerTitle: 'Register to follow ' + props.game.name,
            defaultForm: 'login'
        })
    } else {
        if (isFollowing.value) {
            if (props.confirmUnfollow) {
                displayConfirmUnfollow.value = true
            } else {
                unfollow()
            }
        } else {
            follow()
        }
    }
}

const classes: [string, null] = useAttrs().class ?? ''
</script>

<template>
    <div>
        <div
            class="w-full">
            <Button
                :key="`following-${isFollowing}`"
                v-tooltip="small ? (isFollowing ? `Unfollow` : `Follow`) : ``"
                :variant="isFollowing ? 'white' : variant"
                :small="small"
                :x-small="xSmall"
                icon-first
                :class="classes.includes('w-full') ? 'w-full' : ''"
                :icon-only="iconOnly"
                :icon="isFollowing ? MinusIcon : PlusIcon"
                @click="handleClick">
                {{ isFollowing ? 'Following' : 'Follow' }}
            </Button>
        </div>

        <Modal
            :show="displayConfirmUnfollow"
            max-width="md"
            @close="displayConfirmUnfollow = false">
            <template #title>
                Unfollow {{ game.name }}
            </template>

            <template #content>
                Are you sure you want to unfollow {{ game.name }}
            </template>

            <template #footer>
                <div class="flex w-full flex-row items-center justify-end gap-4">
                    <Button
                        variant="white"
                        @click="displayConfirmUnfollow = false">
                        Cancel
                    </Button>

                    <Button
                        variant="secondary"
                        @click="unfollow()">
                        Yes, unfollow
                    </Button>
                </div>
            </template>
        </Modal>
    </div>
</template>

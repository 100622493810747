<script setup lang="ts">
import SocialLinks from '@/Components/Global/SocialLinks.vue'
import NavigationMobileNavItem from '@/Components/Navigation/NavigationMobileNavItem.vue'
import type { NavItem } from '@/Types/NavItem'
import { Link, router } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { useIsAuthed } from '@/Composables/useIsAuthed'
import { onMounted, type PropType, type Ref, ref, watch } from 'vue'
import { ArrowUpRightIcon, UserCircleIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
    menu: Array as PropType<NavItem[]>,
    open: {
        type: Boolean,
        default: false
    }
})

const navOpen: Ref<boolean, boolean> = ref(props.open)
const displayNavItems: Ref<boolean, boolean> = ref(props.open)

const emit = defineEmits(['close'])

function openNav () {
    navOpen.value = true
    document.body.style.overflow = 'hidden'
    setTimeout(() => {
        displayNavItems.value = true
    }, 150)
}

function toggleNav () {
    navOpen.value === true ? closeNav() : openNav()
}

function closeNav () {
    if (navOpen.value) {
        navOpen.value = false
        document.body.style.overflow = ''
        displayNavItems.value = false
        emit('close')
    }
}

function show (item: NavItem): boolean {
    let canShow = (item.if !== undefined ? item.if : true)

    if (item.permission !== undefined && canShow) {
        canShow = item.permission === true
    }

    return canShow
}

watch(() => props.open, toggleNav)

onMounted(() => {
    router.on('navigate', closeNav)
})
</script>

<template>
    <div :class="navOpen ? 'pointer-events-auto' : 'pointer-events-none'">
        <div
            :class="navOpen ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'"
            class="absolute left-0 top-16 z-10 h-[calc(100dvh-4rem)] w-10/12 bg-primary dark:bg-zinc-900 transition-all duration-300 ease-out">
            <div class="relative z-10 flex h-full w-full flex-col overflow-y-auto p-2 pb-4">
                <transition-group
                    class="flex flex-col space-y-2"
                    name="slide-fade"
                    tag="ul">
                    <template v-for="(item, i) in menu">
                        <li
                            v-if="displayNavItems && (typeof item.permission === 'undefined' || item.permission === true) && show(item)"
                            :key="i"
                            :style="{'--i': i}">
                            <NavigationMobileNavItem
                                :item="item"
                                @clicked="closeNav" />
                        </li>
                    </template>
                    <li
                        v-if="!useIsAuthed()"
                        class="flex items-center gap-2 absolute bottom-18 w-[calc(100%-1rem)]">
                        <Link
                            :href="useRoute('login')"
                            class="flex w-1/2 rounded-lg py-[calc(var(--spacing)*2.55)] px-2.5 border border-primary-light items-center justify-center text-white bg-primary-light/80 text-sm">
                            <ArrowUpRightIcon class="w-5 mr-2" />
                            Log in
                        </Link>
                        <Link
                            :href="useRoute('register')"
                            class="flex w-1/2 rounded-lg p-2.5 px-2.5 border border-white items-center justify-center text-zinc-800 bg-white text-sm">
                            <UserCircleIcon class="w-5 mr-2" />
                            Sign up
                        </Link>
                    </li>
                </transition-group>
                <div class="-mx-2 mt-auto">
                    <div class="mt-4 border-t border-white/10 px-4 pt-4 dark:border-zinc-700">
                        <div class="flex items-center text-zinc-900 space-x-8 dark:text-white">
                            <SocialLinks white />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
